<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
         <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <div class="mr-1">
              <label for="mulai">Tanggal Mulai</label>
              <b-form-datepicker id="mulai" v-model="localData.tanggal_mulai" class="mb-2" :locale="'id'"></b-form-datepicker>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <div class="mr-1">
              <label for="selesai">Tanggal Selesai</label>
              <b-form-datepicker id="selesai" v-model="localData.tanggal_selesai" class="mb-2" :locale="'id'"></b-form-datepicker>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >
           <div class="mr-1">
              <label for="">Deskripsi</label>
              <quill-editor
                  id="deskripsi"
                  :options="snowOption"
                  style="height: 400px; overflow-y: auto;"
                  v-model="localData.deskripsi"
                />
            </div>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2 mt-2"
        >
          <div class="d-flex">
            <b-button class="btn" variant="primary" @click="updateData">Simpan</b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
  getSaranById,
  updateSaran,
} from '@/connection-api/master'
import { quillEditor } from 'vue-quill-editor'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    quillEditor,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    instansiId: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    showEditData: {},
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const response = await getSaranById(this.instansiId)
      if(response.data.success === true)
      {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Analisa Persyaratan',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data berhasil didapatkan!.',
          },
        })
        this.localData = response.data.data
      }
    },
    close() {
      this.$emit('emitCloseEditData')
    },
    async uploadLogo(id)
    {
      if(this.localData.file !== null)
      {
        const responseUpload = await uploadFileLogo(id, this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
      }
    },
    async updateData()
    {
      const response = await updateSaran(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Analisa Persyaratan',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$root.$emit('bv::hide::modal', 'modal_edit_saran' + this.instansiId);
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Analisa Persyaratan',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
