<template>
      <div>
        <b-button variant="outline-primary" class="mt-2" v-b-modal.modal_dokumen >Investigasi</b-button>
          <div>
            <b-modal id="modal_dokumen" centered title="Investigasi Advokasi" hide-footer>
              <b-row>
                <b-col md="12" class="mb-2">
                  <b-form-group
                    label="No Penerimaan"
                    label-for="No Penerimaan"
                  >
                    <b-form-input 
                      :value="localData.no_penerimaan"
                      :disabled="true"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Tanggal Investigasi Advokasi"
                    label-for="Tanggal Investigasi Advokasi"
                  >
                     <b-form-datepicker id="example-datepicker" v-model="localData.tgl_investigasi_advokasi" locale="ID" class="mb-2"></b-form-datepicker>
                  </b-form-group>
                   <b-form-group
                    label="Catatan"
                    label-for="Catatan"
                  >
                     <b-form-textarea
                      id="textarea"
                      v-model="localData.catatan"
                      placeholder="Enter something..."
                      rows="3"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="12" class="mb-2">
                  <main-tab class="mt-2"/>
                </b-col> -->
                <b-col md="12" class="mb-2">
                  <div class="d-flex justify-content-end">
                     <b-form-group>
                      <b-button class="btn mr-1" variant="outline-primary" @click="close" ref="btnClose">Tutup</b-button>
                    </b-form-group>
                    <b-form-group>
                      <b-button class="btn" variant="primary" @click="simpan">Simpan</b-button>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </b-modal>
          </div>
      </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import MainTab from '@/views/laporan/detail-verifikasi/MainTab.vue'
import TenagaAhli from '@/views/tenaga-ahli/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BLink,
  BFormRadio,
  BFormDatepicker,
  BCollapse,
  BCard,
  VBToggle,
} from 'bootstrap-vue'
import {
  downloadDokumen,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import Ripple from 'vue-ripple-directive'
import {
  getTenagaAhli,
  getStatusLaporan,
  simpanInvestigasi,
} from '@/connection-api/master'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    MainTab,
    BLink,
    BFormRadio,
    vSelect,
    BFormDatepicker,
    BCollapse,
    BCard,
    TenagaAhli
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    localData: {},
  },
  data() {
    return {
      selected: null,
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      tenagaAhliOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tenaga Ahli --' },
        ],
      },
      statusLaporanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Status Kelengkapan Berkas --' },
        ],
      },
    }
  },
  methods: {
    close(){
      this.$root.$emit('bv::hide::modal', 'modal_dokumen', '#btnClose')
    },
    closeTambahTenagaAhli(){
      this.$root.$emit('bv::hide::modal', 'modal_tenaga_ahli', '#btnClose')
    },
    async simpan()
    {
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await simpanInvestigasi(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Investigasi Advokasi',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$router.push({name: 'Investigasi Advokasi'})
          this.close()
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Investigasi Advokasi',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
    async getTenagaAhliIdAfterCreated(value)
    {
      this.tenagaAhliOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tenaga Ahli --' },
        ],
      }
      const response = await getTenagaAhli()
      this.mappingSelectOption(response.data, this.tenagaAhliOptions.options)

      this.localData.tenaga_ahli_verifikasi_id = value
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
   async lihatData(file) {
      try {
        const resp = await downloadDokumen(file);
        var type_file = file.substring(file.length - 4);
        if (type_file === ".pdf") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/pdf" })
          );
        } else {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpeg" })
          );
        }
        window.open(this.imageFile);
      } catch (error) {
        this.makeToast("danger", JSON.stringify("File tidak ditemukan!"));
      }
    },
  }
}
</script>
