<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <div class="mr-1">
              <label for="mulai">Tanggal Mulai</label>
              <b-form-datepicker id="mulai" v-model="localData.tanggal_mulai" class="mb-2" :locale="'id'"></b-form-datepicker>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <div class="mr-1">
              <label for="selesai">Tanggal Selesai</label>
              <b-form-datepicker id="selesai" v-model="localData.tanggal_selesai" class="mb-2" :locale="'id'"></b-form-datepicker>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >
           <div class="mr-1">
              <label for="">Deskripsi</label>
              <quill-editor
                  id="deskripsi"
                  :options="snowOption"
                  style="height: 400px; overflow-y: auto;"
                  v-model="localData.deskripsi"
                />
            </div>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
            <div class="mt-2">
              <b-button class="btn btn-primary" variant="primary" @click="tambahData">Simpan</b-button>
            </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
    tambahSaran,
} from '@/connection-api/master'
import { quillEditor } from 'vue-quill-editor'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    quillEditor,
  },
  data() {
    return {
       snowOption: {
        theme: 'snow',
      },
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
        tanggal_mulai: new Date().toISOString().substr(0, 10),
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    pihak: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
  },
  methods: {
    async tambahData()
    {
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await tambahSaran(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Saran',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$emit('closeTambahData')
          this.$root.$emit('bv::hide::modal', 'modal_tambah_saran');
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Saran',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
