<template>
  <component :is="'div'">
    <template>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <b-tabs
            pills
            nav-class="nav-left"
            vertical
          >
            <!-- general tab -->
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kasus Posisi</span>
              </template>
              <kasus-posisi />
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pokok Laporan</span>
              </template>

              <pokok-laporan />
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Analisis Persyaratan Laporan</span>
              </template>
              <analisa-persyaratan-laporan />
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Simpulan</span>
              </template>
              <simpulan />
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Saran</span>
              </template>
              <saran />
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Catatan Tenaga Ahli</span>
              </template>
              <catatan-tenaga-ahli />
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kesimpulan Tim</span>
              </template>
              <kesimpulan-tim />
            </b-tab>
            <!--/ general tab -->
          </b-tabs>
        </b-col>
      </b-row>
    </template>
  </component>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import {
  // getJenisUPT,
  // getKanwil,
  getUPT,
  getJenisAsset,
  getStatusAsset,
  getAllJenisDokumen,
} from '@/connection-api/master'
import storeModule from '@/connection-api/jenis-dokumen/storeModule'
// import ViewDokumenLainnya from '../element/ViewDokumenLainnya.vue'
// import ViewDokumenFoto from '../element/ViewDokumenFoto.vue'
import KasusPosisi from "@/views/kasus-posisi/list/List.vue";
import PokokLaporan from "@/views/pokok-laporan/list/List.vue";
import AnalisaPersyaratanLaporan from "@/views/analisa-persyaratan-laporan/list/List.vue";
import Simpulan from "@/views/simpulan/list/List.vue";
import Saran from "@/views/saran/list/List.vue";
import CatatanTenagaAhli from "@/views/catatan-tenaga-ahli/list/List.vue";
import KesimpulanTim from "@/views/kesimpulan-tim/list/List.vue";

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    // BLink,

    // ViewDokumenLainnya,
    // ViewDokumenFoto,
    KasusPosisi,
    PokokLaporan,
    AnalisaPersyaratanLaporan,
    Simpulan,
    Saran,
    CatatanTenagaAhli,
    KesimpulanTim,
  },
  data() {
    return {
      jenisUptOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis UPT --' },
        ],
      },
      kanwilOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Kanwil --' },
        ],
      },
      uptOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih UPT --' },
        ],
      },
      jenisAssetOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Asset --' },
        ],
      },
      statusAssetOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Status Asset --' },
        ],
      },
      assetId: null,
      dataMasterJenisDokumenLainnya: null,
      dataMasterJenisDokumenFoto: null,
    }
  },
  async beforeMount() {
    this.assetId = Number(this.$route.params.id)
  },
  methods: {
    async reloadUPTOptions(kanwilId) {
      this.uptFilter = null
      this.uptOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih UPT --' },
        ],
      }
      const responseUptOptions = await getUPT(kanwilId)
      this.mappingSelectOptionIds(responseUptOptions.data, this.uptOptions.options)
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    mappingSelectOptionIds(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.ids,
          })
        })
      }
    },
  },
}
</script>

<style>

</style>
